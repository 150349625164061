<template>
	<div class="JsonAutotestSettingsField" :class="{ error }" v-if="value">
		<div class="row">
			<div><label>Priority</label><select v-model="value.priority" @input="validate" style="width: 50px;"><option value="1">1</option><option value="2">2</option><option value="3">3</option></select></div>
			<div><label>Schedule</label><select v-model="value.schedule" @input="validate" style="width: 80px;"><option value="daily">daily</option><option value="hourly">hourly</option><option value="15min">15min</option></select></div>
			<div><label>SLA Class</label><select v-model="value.slaClass" @input="validate" style="width: 80px;"><option value="high">high</option><option value="normal">normal</option><option value="low">low</option><option value="no">no</option></select></div>
			<div><label>Subsystem</label><input type="text" v-model="value.subsystem" @input="validate" /></div>
			<div><label>Active From</label><input type="date" v-model="value.activeFrom" @input="validate" style="width: 80px;" /></div>
			<div><label>Active Until</label><input type="date" v-model="value.activeUntil" @input="validate" style="width: 80px;" /></div>
		</div>
		<div class="row">
			<div><label>Runners</label></div>
			<div class="row"><input type="checkbox" :id="id + '-cypress'" v-model="value.runners" value="cypress" /><label :for="id + '-cypress'">Cypress</label></div>
			<div class="row"><input type="checkbox" :id="id + '-karate'" v-model="value.runners" value="karate" /><label :for="id + '-karate'">Karate</label></div>
		</div>
		<div v-if="entry.fields.targets && entry.fields.targets[defaultLocale]"
			style="display: flex; flex-direction: column; gap: 10px;"
		>
			<div v-for="entryLink in entry.fields.targets[defaultLocale]" :key="entryLink.sys.id"
				style="display: flex; flex-direction: column; gap: 10px;"
			>
				<Entry noHeader :entry="entryLink" class="entryLink"
					@click="$emit('subedit', entryLink.sys, () => { eventBus.$emit('reloadEntry_' + entryLink.sys.id) })"
				>
					<template #details="{ entry }">
						<a v-if="entry?.fields?.metisId?.[defaultLocale]"
							:href="'https://metis.alturos.com/installation/' + entry?.fields?.metisId?.[defaultLocale]"
							target="_blank"
							@click.stop=""
							style="position: absolute; top: 8px; right: 8px;"
						>
							{{ entry?.fields?.metisId?.[defaultLocale] }}
						</a>
						<div class="runners" style="margin-top: 5px; display: flex; flex-direction: column; gap: 5px;">
							<div v-for="(runner, r) in value.runners" :key="runner">
								<div class="runner row" style="gap: 5px; align-items: start;">
									<!-- TODO: link to item {{ entryLink.sys.id }} / {{ runner }} -->
									<!--<button>item</button>-->
									<div style="width: 50px; font-size: smaller; padding-top: 7px;">{{ runner }}</div>
									<JsonAutotestTimeline
										v-model="timelineModel"
										:runner="runner"
										:testcase="testcase"
										:target="entry"
										:showScale="r == value.runners.length - 1"
									/>
									<!--<button>Run Now</button>-->
								</div>
							</div>
						</div>
					</template>
				</Entry>
			</div>
		</div>
	</div>
</template>

<script>
import Entry from './Entry.vue'
import { field } from './FieldMixin.js'
import JsonAutotestTimeline from './JsonAutotestTimeline.vue'
import moment from 'moment'

export default {
	components: { Entry, JsonAutotestTimeline },
	name: 'JsonAutotestSettingsField',
	mixins: [ field ],
	inject: [ 'defaultLocale', 'eventBus' ],
	props: {
		value: [ Object ],
		entry: Object,
	},
	data: () => ({
		autoModel: false,
		id: 'JASF-' + Math.random().toString(36).substring(2, 15),
	}),
	computed: {
		testcase() {
			return this.entry
		},
		timelineModel() {
			return {
				fromDate: moment().add(-1, 'days').toISOString(),
				toDate: new Date().toISOString(),
				interval: this.interval,
			}
		},
		interval() {
			if (this.value.schedule == '15min') return '15m'
			if (this.value.schedule == 'hourly') return '1h'
			if (this.value.schedule == 'daily') return '1d'
		},
	},
	watch: {
		value: {
			handler() {
				this.$emit('change', this.value)
			},
			deep: true,
		},
		'value.schedule'(n) {
			this.timelineModel.interval = this.interval
		},
	},
	methods: {
		validate() {
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (!this.value) this.$emit('input', { activeFrom: '', activeUntil: '', priority: 1, schedule: 'hourly', slaClass: 'low', subsystem: '', runners: [] })
			if (!this.value.runners) this.$set(this.value, 'runners', [])
		})
	},
}
</script>

<style scoped>
.JsonAutotestSettingsField { background: var(--color-element-light); border: 1px solid var(--color-element-mid); border-radius: 5px; padding: 10px; display: flex; flex-direction: column; gap: 10px; }
.row { display: flex; gap: 10px; align-items: center; }
.row > div:not(.row) { display: flex; flex-direction: column; gap: 5px; }
label { color: var(--color-text-lightest); }
select,
input { font-size: inherit; padding: 5px; border: 1px solid var(--color-element-mid); border-radius: 5px; }
button { padding: 7px 10px; border-radius: 5px; background: var(--color-element-dark); color: white; border: 0; }
.spacer { flex: 1; }
</style>

<style>
.JsonAutotestSettingsField .entryLink .entryBody { padding: 8px 10px; }
</style>
