
import Menu from './Menu.vue'
import EnvironmentsMenu from './EnvironmentsMenu.vue'
import { userName } from '../utils'

export default {
	name: 'MainMenu',
	components: { Menu, EnvironmentsMenu },
	inject: [ 'environment', 'base', 'permissions' ],
	props: {
		space: Object,
		locales: Array,
		defaultLocale: String,
		me: Object,
		uiConfig: Object,
	},
	data: () => ({
		showEnvironments: false,
	}),
	computed: {
		username() {
			if (!this.me) return ''
			return userName(this.me)
		}
	},
	methods: {
		logout() {
			console.log('user log out')
			window.localStorage.removeItem('token')
			const config = (window as any).config
			if (config?.FEATURE_LOGIN_KEYCLOAK == 'true') {
				// TODO: somehow get realm name from conf?
				window.location.href = config.keycloakUrl + '/auth/realms/MyServices/protocol/openid-connect/logout?'
					+ '&client_id=contenthub-ui'
					+ '&post_logout_redirect_uri=' + encodeURIComponent(location.protocol + '//' + location.host + location.pathname)
			}
			else {
				this.$el.dispatchEvent(new CustomEvent('401', { bubbles: true, detail: { byUser: true } }))
			}
		},
	},
	mounted() {
		this.showEnvironments = !this.$route.params.spaceId && this.$route.path.indexOf('/session') != 0
	},
}
