
import { field } from './FieldMixin.js'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
	components: { LoadingSpinner },
	name: 'PeakAttributeField',
	mixins: [ field ],
	inject: [ 'endpoint', 'eventBus', 'defaultLocale' ],
	props: {
		value: [ Number, String ],
		attribute: { type: String, default: 'ProductType' },
	},
	data: () => ({
		model: null,
		options: null,
		loading: false,
		showOptions: false,
	}),
	computed: {
		min() {
			return this.validations.size?.min
		},
		max() {
			if (this.def.type == 'Symbol')
				return this.validations.size?.max ?? 255
			return this.validations.size?.max ?? 50000
		},
	},
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
	methods: {
		async loadOptions() {
			this.loading = true
			try {
				// TODO: if we want these fields to also work on other envs,
				//       we'll have to first identify the matching testcase env.
				const r = await this.$httpGet(this.endpoint + '/entries', {
					content_type: 'target',
					'fields.type': 'live',
				})
				const liveTarget = r.items[0]
				if (!liveTarget) { this.eventBus.$emit('addToastMessage', 'No live target found - cannot get values', 'error'); return }
				const metisId = liveTarget.fields.metisId[this.defaultLocale]
				if (!metisId) { this.eventBus.$emit('addToastMessage', 'No metisId found - cannot get values', 'error'); return }

				// TODO: MYS live url - needs deployment!
				const targetClass = 'PeakExecutive'
				const method = 'getEvent' + this.attribute + 'Options'
				const options = await this.$httpPost('https://myservices.lakeside.peaksolution.com/api2/rmi?' + targetClass + '.' + method,
					{ targetClass, method, args: [ metisId ] },
				)
				// TODO: the name from the admin store is potentially not a good idea here as
				//       peak api might have a different name (different locale!)
				return options.map(o => { o.id = `${ o.id }/${ o.name }`; return o })
			}
			finally {
				this.loading = false
			}
		},
		async toggleOptions() {
			this.showOptions = !this.showOptions
			if (this.showOptions && !this.options?.length) {
				this.options = await this.loadOptions()
			}
			this.$nextTick(() => {
				if (this.showOptions) {
					this.$el.querySelector('.option.selected')?.scrollIntoView({ block: 'nearest' })
				}
			})
		},
	},
}
