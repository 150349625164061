// DATALOG //////////

export type Feature = 'role' | 'entry' | 'bulk'

export type Action = 'read' | 'upsert' | 'delete' | 'publish'

export const actions = [ 'read', 'upsert', 'publish', 'delete' ]

// the string[] is a list of tags
export type Rights = { allow?: boolean | string[], deny?: boolean | string[] }

export type ActionRights = { [ action in Action ]?: Rights }

export type RightsTree = { [ contentType: string ]: ActionRights }

export const relations = [
	{ relation: 'user:canUseFeature', feature: '' },
	{ relation: 'user:isAllowed', action: '', contentTypes: [], tags: [] },
	{ relation: 'user:isDenied', action: '', contentTypes: [] },
	{ relation: 'user:tagWith', tag: '' },
	{ relation: 'user:isAdmin', value: true },
]

export function parsePolicies(string, extract = true) {
	if (extract) {
		// when a line only contains whitespace, remove the whitespace
		string = string.replace(/\n[\s]+\n/g, '\n\n')
		// append a newline, so the RE is simpler
		string = string + '\n'
		// set a marker for block ends
		string = string.replace(/\n\n/g, '›')
		// extract the generated block
		string = string.match(/\/\/ ?GENERATED_UI([^›]*)›/)?.[1] ?? ''
	}
	const lines = string.trim().split(/\n/)
	// parse the lines into the policies array
	return lines
		.map(line => line.trim())
		.filter(line => line != '')
		.map(line => {
			if (line.startsWith('//')) return null
			//console.log(line)
			const m = line.match(/^([a-zA-Z0-9:]*)\(([^\)]*)\);?$/)
			if (!m) {
				console.warn('cannot parse line', line)
				return null
			}
			const relation = m[1]
			const paramString = '[' + m[2] + ']'
			let params
			try {
				params = JSON.parse(paramString)
			}
			catch (e) {
				console.warn('cannot parse json params', paramString, e)
				return null
			}
			const r = { relation }
			for (const template of relations) {
				if (template.relation != relation) continue
				for (const key in template) {
					if (key == 'relation') continue
					r[key] = params.shift() ?? template[key]
				}
			}
			return r
		})
}

export function stringifyPolicies(policies, intoString) {
	let block = '// GENERATED_UI\n' + policies.map(policy => {
		const relation = relations.find(template => template.relation == policy.relation)
		if (!relation) {
			console.warn('unknown relation', policy.relation)
			return ''
		}

		const params = []
		for (const key in relation) {
			if (key == 'relation') continue
			let param = policy[key]
			if (key == 'tags') param = param?.map?.(tag => tag.sys?.id ?? tag) ?? []
			if (key == 'value') param = !!param
			if (key == 'tag') param = param?.sys?.id ?? param
			params.push(param)
		}
		return `${ policy.relation }(${ params.map(param => JSON.stringify(param)).join(', ') });`
	}).join('\n') + '\n'
	let string = intoString + '\n'
	string = string.replace(/\n\n/g, '›')
	if (string.match(/\/\/ ?GENERATED_UI([^›]*)›/))
		string = string.replace(/\/\/ ?GENERATED_UI([^›]*)›/, block + '\n')
	else
		string = string + '\n' + block
	string = string.replace('›', '\n\n')
	string = string.trim() + '\n'
	return string
}

export function buildRightsTree(policies: any[]): RightsTree {
	const r: RightsTree = {}
	const types = Object.keys(window['typeLookup'])
	for (const t of types) r[t] = {}

	const merge = (contentType: string, action: Action, policy) => {
		const right = policy.relation == 'user:isAllowed' ? 'allow' : 'deny'
		// init empty holder for unknown types
		if (!r[contentType]) r[contentType] = {}
		// init empty right
		if (!r[contentType][action]) r[contentType][action] = {}

		const tags = !policy?.tags?.length ? [] : policy.tags.map(tag => tag.sys?.id ?? tag)

		const rights = r[contentType][action]
		// if it is already set to true, we keep it
		if (rights[right] === true)
			return
		// if any rule specifies [], we set to true
		else if (!tags?.length)
			rights[right] = true
		// nothing set yet -> set tags
		else if (rights[right] === undefined)
			rights[right] = tags
		// otherwise we merge the tags
		else if (Array.isArray(rights[right]))
			rights[right] = (rights[right] as string[]).concat(tags)
		else
			console.warn('unexpected policy', contentType, policy, action, rights)
	}

	for (const policy of policies) {
		if (!policy) continue
		if (policy.relation == 'user:isAllowed' || policy.relation == 'user:isDenied') {
			const ts = policy.contentTypes?.length ? policy.contentTypes : types
			for (const t of ts) {
				const targetActions = !policy.action || policy.action == 'all' ? actions : [ policy.action ]
				for (const action of targetActions) {
					merge(t, action, policy)
				}
			}
		}
	}
	return r
}

// IAM //////////

export type IamEffect = 'allow' | 'deny'
export type IamAction = 'ch:base:*' | 'ch:tag:*' | 'ch:space:*' | 'ch:entry:read' | 'ch:entry:upsert' | 'ch:entry:delete' | 'ch:entry:publish' | 'ch:tagEntry'
export type IamConditionType = 'stringEquals' | 'stringNotEquals' | 'listContains' | 'listNotContains'
export type IamConditionKey = 'ch:entry/contentType' | 'ch:feature/name' | 'ch:entry/tags'

// the storage format of IAM is defined by Amazon

export type IamPolicyStorage = {
	version: string
	statement: IamPolicyStatementStorage[]
}

export type IamPolicyStatementStorage = {
	effect: IamEffect
	action?: IamAction[]
	resource?: string[]
	condition?: {
		[ type in IamConditionType ]?: {
			[ key in IamConditionKey ]?: string | string[]
		}
	}
}

// but we use a more convenient format for our internal representation (for the editor and for the permission checks)
// the functions parseIam and stringifyIam convert between the two formats

export type IamPolicy = {
	version: string
	statement: IamPolicyStatement[]
}

export type IamPolicyStatement = {
	effect: IamEffect
	action?: IamAction[]
	resource?: string[]
	condition?: IamCondition[]
}

export type IamCondition = {
	/* this is the storage format:
	stringEquals: { [ key in IamConditionKey ]: string[] }
	stringNotEquals: { [ key in IamConditionKey ]: string[] }
	*/
	type: IamConditionType
	key: IamConditionKey
	param: string | string[]
}

// TODO: integrate this with our permission checks where we still use the Action strings?
/*
export function mapActionToIamAction(action: Action): IamAction {
	switch (action) {
		case 'read': return 'ch:readEntry'
		case 'upsert': return 'ch:upsertEntry'
		case 'delete': return 'ch:deleteEntry'
		case 'publish': return 'ch:publishEntry'
	}
}
*/

export function mapIamActionToAction(action: IamAction): Action {
	switch (action) {
		case 'ch:entry:read': return 'read'
		case 'ch:entry:upsert': return 'upsert'
		case 'ch:entry:delete': return 'delete'
		case 'ch:entry:publish': return 'publish'
	}
}

type ContentTypePolicyStatement = {
	tags?: string[]
	notTags?: string[]
	resource: string[]
	resourceStartsWith: string[]
	condition: IamCondition[]
}

export type NewRights = {
	[ effect in IamEffect ]?: ContentTypePolicyStatement[]
}

export type NewRightsTree = {
	[ contentType: string ]: {
		[ action in IamAction ]?: NewRights
	}
}

export function parseIam(storage: IamPolicyStorage): IamPolicy {
	if (!storage) return null
	const data: IamPolicy = {
		version: storage.version,
		statement: [],
	}
	for (const statementStorage of storage.statement ?? []) {
		const statement: IamPolicyStatement = {
			effect: statementStorage.effect,
			action: [ ...statementStorage.action ],
			// TODO: do we need a symmetric transform to normalizeTags?
			resource: [ ...statementStorage.resource ],
			condition: [],
		}
		for (const type in statementStorage.condition ?? {}) {
			const conditionStorage = statementStorage.condition[type]
			for (const key in conditionStorage ?? {}) {
				const param = conditionStorage[key]
				statement.condition.push({ type, key, param } as IamCondition)
			}
		}
		data.statement.push(statement)
	}
	return data
}

export function stringifyIam(data: IamPolicy): IamPolicyStorage {
	// resources, params and param array items can be tags, we need to convert those to strings
	const storage: IamPolicyStorage = {
		version: new Date().toISOString().substring(0, 16),
		statement: [],
	}
	for (const statement of data?.statement ?? []) {
		const statementStorage: IamPolicyStatementStorage = {
			effect: statement.effect,
			action: [ ...statement.action ],
			resource: normalizeTags(statement.resource),
			condition: {},
		}
		for (const cond of statement.condition ?? []) {
			const type = cond.type
			const key = cond.key
			const param = normalizeTags(cond.param)
			if (Array.isArray(param)) {
				for (let i = 0; i < param.length; i++)
					param[i] = normalizeTags(param[i])
			}
			if (!statementStorage.condition[type]) statementStorage.condition[type] = {}
			statementStorage.condition[type][key] = param
		}
		storage.statement.push(statementStorage)
	}
	return storage
}

// TODO: incomplete!
//       we do not handle the resource paths here..
//       that actually means the rights tree is not useable for the permission system.
//       how else can we quickly check permissions without the rights tree?
//       we may want to at least groupthe rights by types (similar to the right tree) to speed things up a bit.
export function buildRightsTreeIam(policies: IamPolicyStatement[]): NewRightsTree {
	const r: NewRightsTree = {}
	const allTypes = Object.keys(window['typeLookup'])
	for (const t of allTypes) r[t] = {}

	for (const policy of policies) {
		if (!policy) continue
		if (!policy.action?.length) continue
		
		let types = []
		let tags = []
		let notTags = []
		let foundTypeRules = false
		let foundTagRules = false
		let foundNotTagRules = false
		const filteredConditions: IamCondition[] = []
		for (const condition of policy.condition ?? []) {
			if (condition.key == 'ch:entry/contentType') {
				if (condition.type == 'stringEquals') {
					foundTypeRules = true
					if (!condition.param?.length) continue
					types.push(...condition.param)
				}
				if (condition.type == 'stringNotEquals') {
					foundTypeRules = true
					for (const t of condition.param) {
						if (!condition.param?.length) continue
						if (!types.includes(t)) continue
						types.splice(types.indexOf(t), 1)
					}
				}
			}
			else if (condition.key == 'ch:entry/tags') {
				if (condition.type == 'listContains') {
					foundTagRules = true
					if (!condition.param?.length) continue
					tags.push(...condition.param)
				}
				if (condition.type == 'listNotContains') {
					foundNotTagRules = true
					if (!condition.param?.length) continue
					notTags.push(...condition.param)
				}
			}
			else {
				// we only maintain the conditions that are not for tags or types
				filteredConditions.push({
					type: condition.type,
					key: condition.key,
					param: normalizeTags(condition.param),
				})
			}
		}
		tags = normalizeTags(tags)
		notTags = normalizeTags(notTags)
		if (!foundTagRules) tags = null
		if (!foundNotTagRules) notTags = null
		if (!foundTypeRules) types = allTypes

		const cts: ContentTypePolicyStatement = {
			tags,
			notTags,
			// we separate into prefix expressions and full resurces paths, so we dont need to check / transform later
			resource: policy.resource?.filter?.(r => !r.endsWith('*')) ?? [],
			resourceStartsWith: policy.resource?.filter?.(r => r.endsWith('*'))?.map?.(r => r.replace(/\*$/, '')) ?? [],
			condition: filteredConditions,
		}

		for (const t of types ?? []) {
			for (const iamAction of policy.action ?? []) {
				const action = mapIamActionToAction(iamAction)
				if (!action) { continue }
				if (!r[t]) r[t] = {}
				if (!r[t][action]) r[t][action] = {}
				const rights: NewRights = r[t][action]
				if (!rights[policy.effect]) rights[policy.effect] = []
				rights[policy.effect].push(cts)
			}
		}
	}

	return r
}

// the rights tree gives us a quick way to get ContentTypePolicyStatement objects
// for a given content type and action. finally we need to check the remaining condition
// parts (tags, resource path).

export type ConditionContext = {
	resource?: string
	tags?: string[]
}

export function statementConditionsMatch(statement: ContentTypePolicyStatement, context: ConditionContext) {
	if (statement.resource && statement.resource.every(resource => resource == context.resource)) return true
	if (statement.resourceStartsWith && statement.resourceStartsWith.every(resource => !context.resource.startsWith(resource))) return true
	if (statement.tags && statement.tags.some(tag => !context.tags?.includes(tag))) return false
	if (statement.notTags && statement.notTags.some(tag => context.tags?.includes(tag))) return false
	return true
}

// UTILS //////////

export function normalizeTags(tags) {
	if (!tags) return tags
	if (!Array.isArray(tags)) return tags
	const r = []
	for (const tag of tags) {
		r.push(tag?.sys?.id ?? tag)
	}
	return r
}

export const mockAlturosUsers = {
	'nils.bahn@alturos.com': { role: 'Space Admin' },
	'andreas.hoeffernig@alturos.com': { role: 'Space Admin' },
	'luka.mrcela@alturos.com': { role: 'Space Admin' },
	'nikita.kozlov@alturos.com': { role: 'Space Admin' },
	'anna-lena.joerg@alturos.com': { role: 'Space Admin' },
	'sarah.ebenwaldner@alturos.com': { role: 'Space Admin' },
	'mathias.mirnig@alturos.com': { role: 'Space Admin' },
	'julia.sterba@alturos.com': { role: 'Space Admin' },
	'said.rustamov@alturos.com': { role: 'Space Admin' },
	'gentian.qela@alturos.com': { role: 'Space Admin' },
	'wilhelm.berger@alturos.com': { role: 'Space Admin' },
	'georg.lausegger@alturos.com': { role: 'Space Admin' },
	'sarah.pschernig@alturos.com': { role: 'Space Admin' },
	'mario.gurmann@alturos.com': { role: 'Space Admin' },
	'daniel.wogatai@alturos.com': { role: 'Space Admin' },
	'beverly.ehrlich@alturos.com': { role: 'Space Admin' },
	'sandra.graberski@alturos.com': { role: 'Space Admin' },
	'kerstin.semmelrock@alturos.com': { role: 'Space Admin' },
	'ivana.hassler@alturos.com': { role: 'Space Admin' },
	'rene.dettelbacher@alturos.com': { role: 'Space Admin' },
	'miro.wakounig@alturos.com': { role: 'Space Admin' },
	'michael.tabojer@alturos.com': { role: 'Space Admin' },
	'fabian.waldmann@alturos.com': { role: 'Space Admin' },
	'blend.dehari@alturos.com': { role: 'Space Admin' },
	'eugen.tratnik@alturos.com': { role: 'Space Admin' },
	'bernhard.tatzmann@alturos.com': { role: 'Space Admin' },
	'philipp.wolfger@alturos.com': { role: 'Space Admin' },
	'alexander.plaschke@alturos.com': { role: 'Space Admin' },
	'mathias.kreiner@alturos.com': { role: 'Space Admin' },
	'yevhen.sibahatullin@alturos.com': { role: 'Space Admin' },
	'bence.slajcho@alturos.com': { role: 'Space Admin' },
	'adnan.brdanin@alturos.com': { role: 'Space Admin' },
	'arash.azim-doust@alturos.com': { role: 'Space Admin' },
	'valerii.andrusyk@alturos.com': { role: 'Space Admin' },
	'andreas.lah@alturos.com': { role: 'Space Admin' },
	'stefanie.gallob@alturos.com': { role: 'Space Admin' },
	'owen.powell@alturos.com': { role: 'Space Admin' },
	'dominik.lechl@alturos.com': { role: 'Space Admin' },
	'zvjezdan.dekic@alturos.com': { role: 'Space Admin' },
	'hannes.dermutz@alturos.com': { role: 'Space Admin' },
	'teresa.pachernegg@alturos.com': { role: 'Space Admin' },
	'goran.drazic@alturos.com': { role: 'Space Admin' },
	'christian.gattringer@alturos.com': { role: 'Space Admin' },
	'ronald.linasi@alturos.com': { role: 'Space Admin' },
	'peter.wagner@alturos.com': { role: 'Space Admin' },
	'phillip.loacker@alturos.com': { role: 'Space Admin' },
	'myservices-sync@alturos.com': { role: 'Space Admin' },
	'myservices@alturos.com': { role: 'Space Admin' },
	'cf.management@alturos.com': { role: 'Space Admin' },
	'marketing@alturos.com': { role: 'Space Admin' },
	'james.admin@alturos.com': { role: 'Space Admin' },
	'milorad.malinovic@alturos.com': { role: 'Space Admin' },
	'andreas.csoergo@alturos.com': { role: 'Space Admin' },
	'anton.pum@alturos.com': { role: 'Space Admin' },
	'herwig.probst@alturos.com': { role: 'Space Admin' },
	'juergen.avian@alturos.com': { role: 'Space Admin' },
	'cornelia.pirker@alturos.com': { role: 'Space Admin' },

	'martin.jenny@alturos.com': { role: 'Alturos Employee' },
	'kajetan.funkiewicz@alturos.com': { role: 'Alturos Employee' },
	'rakhat.schober@alturos.com': { role: 'Alturos Employee' },
	'silvia.jochum@alturos.com': { role: 'Alturos Employee' },
	'sabrina.rinderer@alturos.com': { role: 'Alturos Employee' },
	'halil.sallamaci@alturos.com': { role: 'Alturos Employee' },
	'markus.steinbrugger@alturos.com': { role: 'Alturos Employee' },
	'carmen.volina@alturos.com': { role: 'Alturos Employee' },
	'nicole.dreiling@alturos.com': { role: 'Alturos Employee' },
	'nongma.sam@alturos.com': { role: 'Alturos Employee' },
	'theresa.stossier@alturos.com': { role: 'Alturos Employee' },
	'dorothea.loacker-wedenig@alturos.com': { role: 'Alturos Employee' },

	'gilberto.loacker@alturos.com': { role: 'Organisation Admin' },
	'marcel.bricman@alturos.com': { role: 'Organisation Admin' },
	'aylin.gueler@alturos.com': { role: 'Organisation Admin' },
	'walter.unterpirker@alturos.com': { role: 'Organisation Admin' },
	'alexander.stromberger@alturos.com': { role: 'Organisation Admin' },
	'philip.steinkellner@alturos.com': { role: 'Organisation Admin' },
	'gerhard.schaden@alturos.com': { role: 'Organisation Admin' },
	'sandra.gattringer@alturos.com': { role: 'Organisation Admin' },
	'ziga.tomazin@alturos.com': { role: 'Organisation Admin' },
	'christian.mairitsch@alturos.com': { role: 'Organisation Admin' },
	'patrick.klavora@alturos.com': { role: 'Organisation Admin' },
	'maria.linasi@alturos.com': { role: 'Organisation Admin' },
	'helmut.brueckler@alturos.com': { role: 'Organisation Admin' },
}

export function userIsOrganisationAdmin(email: string) {
	return mockAlturosUsers[email]?.role == 'Organisation Admin'
}

export function userIsSpaceAdmin(email: string) {
	return mockAlturosUsers[email]?.role == 'Space Admin'
}

// note that policied may have different blocks.
// at the ui we only deal with the "GENERATED_UI" block.
const policyTestString = `
user:isAdmin(true);

// GENERATED_UI
user:isAllowed("update", ["layoutHeroImage"], ["alturos"]);
user:isAllowed("update", ["layoutHeroImage"]);
user:isAllowed("update");

user:someUnsupportedRole("Editor");`

export function test() {
	console.debug('===============================================================')
	console.debug('TEST input', policyTestString.trim())
	const testParse = parsePolicies(policyTestString)
	console.debug('TEST testParse', JSON.stringify(testParse))
	const testStringify = stringifyPolicies(testParse, policyTestString)
	console.debug('TEST testParse', testStringify)
}