import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Mys from './MYS'
//import VeauryVuePlugin from 'veaury/webpack'
import MDI from '@/components/MDI.vue'
import ActionButton from '@/components/ActionButton.vue'
import Dialog from '@/components/Dialog.vue'
import SystemTags from '@/components/fields/SystemTags.vue'

Vue.config.productionTip = false
//Vue.use(VeauryVuePlugin, { type: 'react' })

// TODO: sentry wrapping destroys the stack trace in the browser
//       figure out a way to do sentry but keeping the dev workflow working
/*Sentry.init({
	Vue,
	// TODO: configure this via env?
	dsn: 'https://7e9d91729ce2267eadbc922cacf55ee0@o4505822393860096.ingest.sentry.io/4505827039772672',
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
		// TODO: other integrations?
	],

	// TODO: configure all this via env?
	// ATT: in prod this should be low!
	// 1.0 = capture 100% of transactions for performance monitoring
	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/contentgraph-editor\.lakeside\.peaksolution\.com/,
		/^https:\/\/editor\.staging\.contenthub\.dev/,
		/^https:\/\/editor\.qa\.contenthub\.dev/,
		/^https:\/\/editor\.contenthub\.dev/,
	],
	// 1.0 = capture 100% of replay of all sessions
	replaysSessionSampleRate: 0.0,
	// 1.0 = capture 100% of replay sessions with an error
	replaysOnErrorSampleRate: 0.0,
})*/

let settings = JSON.parse(window.localStorage.settings ?? 'null')
if (!settings) {
	settings = {
		entryTableLength: 10,
		skin: 'default',
	}
}
let baseHost = location.host.replace('editor.', '')
if (baseHost.indexOf('localhost') != -1)
	baseHost = localStorage.baseHost ? localStorage.baseHost : 'contenthub.dev' // 'qa.contenthub.dev'
settings.baseEndpoint = 'https://cma.' + baseHost
settings.uploadEndpoint = 'https://upload.' + baseHost

// in a deployed environment we hide some errors that are not relevant
// but on localhost we want to have proper error lines which would break otherwise
if (location.href.indexOf('localhost') != -1) {
	const originalConsoleError = console.error
	console.error = (...args) => {
		if (typeof args[0] == 'string') {
			if (args[0].startsWith('[Vue warn]: Injection "endpoint"')) return
			if (args[0].startsWith('Warning: Function components cannot be given refs.')) return
		}
		originalConsoleError(...args)
	}
}

//let config: { [ key: string ]: any } = {}

async function loadConfig() {
	const isStaging = settings.baseEndpoint.indexOf('.qa.') != -1 || settings.baseEndpoint.indexOf('.staging.') != -1
	const isLocal = location.href.indexOf('localhost:808') != -1

	let config: { [ key: string ]: any } = {}
	if (isLocal) {
		config = {
			FEATURE_LOGIN_KEYCLOAK: 'true',
		}
	}
	else {
		try {
			let configUrl = 'https://editor.contenthub.dev/config.json'
			if (isStaging) configUrl = 'https://staging.content-editor.pages.dev/config.json'
			config = await (await fetch(configUrl)).json()
		}
		catch (e) {
			console.warn('Failed to load config. defaults will be used.', e)
		}
	}

	config.isStaging = isStaging
	config.isLocal = isLocal

	let keycloakUrl = 'https://keycloak-cust-01.destos01.prod.alturos.com'
	if (isStaging) keycloakUrl = 'https://keycloak.staging.lakeside.skiline.cc'
	if (isLocal) keycloakUrl = 'https://keycloak.staging.lakeside.skiline.cc'
	config.keycloakUrl = keycloakUrl

	let myservicesUrl = 'https://myservices.alturos.com'
	if (isStaging) myservicesUrl = 'https://myservices.lakeside.peaksolution.com'
	if (isLocal) myservicesUrl = 'http://localhost:8040'
	config.myservicesUrl = myservicesUrl

	return config
}

async function init() {

	const config = await loadConfig()
	;(window as any).config = config

	let token
	let pathname
	const mode = new URLSearchParams(window.location.search).get('mode') ?? 'Standalone'
	if (mode == 'MyServices') {
		if (!document.referrer) {
			alert('Cannot open MyServices mode outside of MyServices')
			throw new Error('no referrer')
		}

		const mys = new Mys(new URL(document.referrer).origin, {
			localeChanged(locale) {
				console.log('localeChanged', locale)
			}
		})

		let [ client, mysUser, config ] = await Promise.all([ mys.getClient(), mys.getUser(), mys.getAppConfig() ])
		console.log('mys', client, mysUser, config)

		pathname = `/spaces/${client.fields.contentfulSpace.de}/environments/master/entries`

		// we allow settings to be overridden in the MyServices app settings
		settings = { ...settings, ...config.settings }

		if (!mysUser?.sys?.id) {
			alert('Could not get user from MyServices.')
			throw new Error('No user')
		}

		token = mysUser.kc_token

		// probably an impersonated user -> show a specific error message
		if (!token) {
			alert('Content-Hub does not support MyServices impersonation. Please login with your own account.')
			throw new Error('No token, probably because of impersonation.')
		}

		/*
		// token exchange
		const response = await fetch(settings.baseEndpoint + '/auth/loginWithExternalToken', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				access_token: mysUser.kc_token,
			})
		})
		token = (await response.json()).token

		if (!token) { throw new Error("not auth token")}
		*/
	}
	else if (mode == 'Standalone') {
		// MYS auth uses the query and a 'token' param,
		// the new Keycloak auth uses the hash and 'access_token'
		let params = window.location.search
		if (!params || !params.includes('token')) params = window.location.hash
		const query = new URLSearchParams(params)
		let queryToken = query.get('access_token')
		if (!queryToken) queryToken = query.get('token')
		const storageToken = window.localStorage.token
		token = queryToken ?? storageToken
		if (token == 'null') token = null

		// TODO: decode the token, read the name and expiry and display somewhere

		// when forceLogin is true, we instruct MYS to show the login screen regardless of the current login state
		const forwardToAuthPage = async (forceLogin = false) => {
			console.log('resetting token and sending to auth')
			window.localStorage.token = null

			// TODO: this must be removed once mys auth works fully
			if (false) {
				window.location.href = settings.baseEndpoint.replace('//cma.', '//auth.')
				return
			}

			let url
			let query
			if (config.FEATURE_LOGIN_KEYCLOAK == 'true') {
				url = config.keycloakUrl + '/auth/realms/MyServices/protocol/openid-connect/auth'
				query = {
					response_type: 'token',
					client_id: 'contenthub-ui',
					redirect_uri: window.location.href,
					scope: 'profile',
					// TODO: this is not supported by keycloak - do we have a different solution?
					//       what we actually want to do is a logout
					forceLogin: forceLogin ? 'true' : 'false',
					// TODO: this is not supported by keycloak
					//       can we use the state to transport this??
					spaceId: window.location.href.match(/spaces\/([^/]+)/)?.[1],
					//state: '',
				}
			}
			else {
				// TODO: remove this mode once keycloak auth is activated
				// previously we used MYS as auth provider
				url = config.myservicesUrl + '/auth'
				query = {
					response_type: 'token',
					client_id: 'ContentHub',
					redirect_uri: window.location.href,
					scope: 'login+profile',
					// state: '',
					forceLogin: forceLogin ? 'true' : 'false',
					spaceId: window.location.href.match(/spaces\/([^/]+)/)?.[1],
				}
			}
			window.location.href = url + '?' + new URLSearchParams(query).toString()
		}

		// some pages dispatch a 401 event
		document.body.addEventListener('401', async (e: any) => {
			console.log('got 401', e?.detail)
			// 401 happened on a page that was the login target -> prevent loops
			if (location.href.indexOf('token=') != -1) {
				window.alert('probable auth problem. got invalid token!')
				console.error('token', queryToken, e)
				return
			}
			await forwardToAuthPage(e?.detail?.byUser)
		})

		if (!token) {
			console.log('no token, forwarding to auth')
			await forwardToAuthPage()
			return
		}
		query.delete('token')
		pathname = window.location.pathname + '?' + query.toString()
	}
	else {
		throw new Error('Unknown mode: ' + mode)
	}

	if (!token) throw new Error('No token')

	if (window.localStorage.token != token) {
		window.localStorage.token = token
		router.push(pathname)
	}

	Vue.component('mdi', MDI)
	Vue.component('ActionButton', ActionButton)
	Vue.component('Dialog', Dialog)
	// ATT: i would prefer not to have SystemTags global, but it had to be to make the
	//      extends on AssetFilter work, because otherwise we had some circular dependency
	Vue.component('SystemTags', SystemTags)

	const v = new Vue({
		router,
		render: h => h(App),
		provide() {
			return {
				settings
			}
		},
	}).$mount('#app')

	// we forward the router before event into the application, so each component may intercept it
	router.beforeEach((to, from, next) => {
		const app = v.$children[0] as any
		app.routerBeforeEach(to, from, next)
	})
}
init()
