<template>
	<div class="FileField" :class="{ error }">
		<div :id="'dropzone' + uniq" ref="dropzone" class="dropzone" v-if="!value"
			@drop.prevent="nativeDropzone" @dragenter.prevent @dragover.prevent
		>
			<div class="message">
				<div>Drag and drop a file to upload&hellip;</div>
				<button style="padding: 0; min-height: 0; margin-top: 50px;" :disabled="disabled">
					<input type="file" @change="uploadFilePicker" style="padding: 10px; margin: -2px;" :disabled="disabled" />
				</button>
			</div>
			<div style="height: 30px; background: green;" :style="{ width: progress + '%' }"></div>
		</div>
		<div v-if="value">
			<Info type="warning" style="margin: 0 0 10px;" v-if="isUnsupportedImage(entry, defaultLocale)">
				You have uploaded a file of type <strong>{{ value.contentType }}</strong> which is an image format, but it is not supported for Content-Hub scaling, so it will not be supported for use as JAMES image!
				Please refer to the <a href="https://docs.contenthub.dev/docs/assets/">documentation</a> to learn about supported image formats.
			</Info>
			<div class="view">
				<span v-if="isImage(entry, defaultLocale)" style="display: inline-block; position: relative; overflow: hidden;">
					<img v-if="value.details" :src="value.url + '?h=300'" @load="loaded = true" />
					<mdi v-else timer-sand class="placeholder" />
					<Cropper v-if="loaded && cropShow"
						:w="value.details.image.width"
						:h="value.details.image.height"
						:aspect="cropAspect"
						@cancel="cropShow = false"
						@save="cropSave"
					/>
				</span>
				<embed v-else-if="isPdf(entry, defaultLocale)" :src="value.url" width="100%" height="500px" />
				<video v-else-if="isAudio(entry, defaultLocale) || isVideo(entry, defaultLocale)" controls width="100%"><source :src="value.url" /></video>
				<mdi v-else-if="isCode(entry, defaultLocale)" file-document-outline class="placeholder" />
				<mdi v-else-if="isText(entry, defaultLocale)" file-code-outline class="placeholder" />
				<mdi v-else file-outline class="placeholder" />
			</div>
			<div v-if="value.details" style="display: flex; gap: 10px; color: silver; margin-bottom: 10px;">
				<div>Size: {{ value.details.size }} Bytes</div>
				<div v-if="value.details.image">
					{{ value.details.image.width }} x {{ value.details.image.height }} Pixels
				</div>
				<div>Type: {{ value.contentType }}</div>
			</div>
			<div class="buttons" :disabled="cropShow || disabled">
				<a class="button" :href="value.url" @click.prevent="download"><mdi download /></a>
				<div class="buttons" v-if="isImage(entry, defaultLocale)" style="gap: 5px;">
					<button class="button" @click="rotate(-90)"><mdi file-rotate-left /></button>
					<button class="button" @click="rotate(90)"><mdi file-rotate-right /></button>
					<!--
					-->
					<Menu ref="resizeMenu">
						<ul>
							<li @click="setWidth"><mdi arrow-expand-horizontal /> Set Width&hellip;</li>
							<li @click="setHeight"><mdi arrow-expand-vertical /> Set Height&hellip;</li>
						</ul>
					</Menu> 
					<button class="button" @click="$refs.resizeMenu.open()"><mdi resize /> <mdi chevron-down /></button>
					<!--
						TODO: ?download=true param (also on non-image types!) to force content-disposition: download
						TODO: the server will probably not be able to do flip, as the base service cant do that
					<img :src="previewUrl" style="max-width: 200px; max-height: 200px;" />
					-->
					<Menu ref="cropMenu">
						<ul>
							<li @click="crop({ w: 1, h: 1 })"><mdi vector-square /> 1:1 Aspect</li>
							<li @click="crop({ w: 4, h: 3})"><mdi vector-rectangle /> 4:3 Aspect</li>
							<li @click="crop({ w: 3, h: 2})"><mdi vector-rectangle /> 3:2 Aspect</li>
							<li @click="crop({ w: 16, h: 9})"><mdi vector-rectangle /> 16:9 Aspect</li>
							<li @click="crop({ w: value.details.image.width, h: value.details.image.height })"><mdi vector-rectangle /> Keep Aspect</li>
							<li @click="crop()"><mdi crop /> Free</li>
						</ul>
					</Menu>
					<button class="button" @click="$refs.cropMenu.open()"><mdi crop /> <mdi chevron-down /></button>
				</div>
				<!-- TODO: actually we also need to delete the file at the server! -->
				<button class="button" @click="deleteFile"><mdi delete /></button>
			</div>
		</div>
		<Dialog ref="preview" :width="450" :height="550">
			<h1>Preview</h1>
			<div class="body">
				<div>
					<img :src="previewUrl" style="display: block; max-width: 400px; max-height: 400px; margin: 10px auto;" />
				</div>
			</div>
			<div class="buttons">
				<ActionButton @click="$refs.preview.close()" class="cancel">Cancel</ActionButton>
				<ActionButton @click="previewConfirm" class="ok">Save</ActionButton>
			</div>
		</Dialog>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'
import { isImage, isPdf, isAudio, isVideo, isCode, isText, isUnsupportedImage } from '../../utils'
import Menu from '../Menu.vue'
import { buildQueryUrl } from '../../plugins/HttpUtil.js'
import Cropper from './Cropper.vue'
import Dialog from '../Dialog.vue'
import Info from '../../views/Info.vue'

export default {
	name: 'FileField',
	components: { Menu, Cropper, Dialog, Info },
	mixins: [ field ],
	inject: [ 'endpoint', 'defaultLocale', 'settings', 'spaceId' ],
	props: {
		value: Object,
		entry: Object,
	},
	data: () => ({
		model: null,
		uniq: Math.floor(Math.random() * 99999),
		dragHot: false,
		progress: 0,
		progressInterval: null,
		emptyParams: {
			rot: 0,
			tt: 0,
			tr: 0,
			tb: 0,
			tl: 0,
			w: -1,
			h: -1,
		},
		params: null,
		loaded: false,
		cropAspect: null,
		cropShow: false,
		previewUrl: null,
	}),
	computed: {
		uploadUrl() {
			let url = this.settings.uploadEndpoint ?? 'https://upload.contenthub.dev'
			return `${ url }/spaces/${ this.spaceId }/uploads`
		},
	},
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
			])
			if (!n) {
				// TODO: launch the picker dialog?
//				this.nativeDropzone()
				//this.uploadcareDropzone()
				//this.filestackDropzone()
			}
		},
		'value.url'(n) {
			this.loaded = false
		},
	},
	methods: {
		uploadFilePicker(e) {
			this.processFile(e.target.files[0])
		},
		nativeDropzone(e) {
			// TODO: reject multiple uploads
			if (e.dataTransfer.items) {
				[...e.dataTransfer.items].forEach((item, i) => {
					if (item.kind === 'file') {
						const file = item.getAsFile()
						this.processFile(file)
					}
				})
			}
			else {
				[...e.dataTransfer.files].forEach((file, i) => {
					this.processFile(file)
				})
			}

			// TODO: trigger autosave after all is done
		},
		processFile(file) {
			const reader = new FileReader()
			reader.onload = async (event) => {
				let content = event.target.result
				await this.uploadContent(content, file)
			}
			reader.readAsArrayBuffer(file)
		},
		async uploadContent(content, file) {
			try {
				this.progress = 5
				this.progressInterval = window.setInterval(() => {
					this.progress += 0.15 + (100 - this.progress) / 200
					if (this.progress >= 100) {
						this.progress = 100
						window.clearInterval(this.progressInterval)
					}
				}, 1000)
				const r = await this.$httpPost(this.uploadUrl, content, { headers: { 'as-use': false } })
				this.progress = 0
				this.$emit('input', {
					contentType: file.type,
					fileName: file.name,
					uploadFrom: {
						sys: {
							type: 'Link',
							linkType: 'Upload',
							id: r.sys.id,
						}
					}
				})
			}
			finally {
				window.clearInterval(this.progressInterval)
			}
		},
		async updateImage(newImage) {
			// TODO: we use defaultLocale because assets are not localizable (or are they somehow?)
			//       actually i would rather we pass down the actual locale
			const locale = this.defaultLocale
			// this.$emit('input', newImage)
			// TODO: save asset here (currently we rely on autosave being done in  2s)
			//       for this we would need to tell the container, NOT to autosave
			//       maybe we can instead directly assign the property to the entity (without emitting)?
			//       something along the lines of:
			//       (but we need to determine the locale and the field! currently we assume the field is open on an asset)
			this.entry.fields.file[locale] = newImage

			// TODO: this also assumes we are working on an asset
			const asset = await this.$httpPut(this.endpoint + '/assets/' + this.entry.sys.id, this.entry, {
				headers: {
					'content-type': 'application/vnd.contentful.management.v1+json',
					'x-contentful-version': this.entry.sys.version,
				},
			})
			// update version, updatedAt, etc.
			this.entry.sys = asset.sys

			// TODO: this gives a "file has already been processed error" - test more
			//       we may need to wait some time because the server still sees the old asset?
			//window.setTimeout(async () => {
				// TODO: this calls an /asset endpoint, but the field may be used for entries too..
				const processResponse = await this.$httpPut(this.endpoint + '/assets/' + this.entry.sys.id + '/files/' + locale + '/process', undefined, {
					headers: {
						'content-type': 'application/vnd.contentful.management.v1+json',
						'x-contentful-version': this.entry.sys.version,
					},
				})
			//}, 2000)
			return asset
		},
		deleteFile() {
			if (!confirm('Do you really want to delete this file?')) return
			this.$emit('input', undefined)
		},
		download() {
			window.open(this.value.url, 'download')
		},
		isImage,
		isPdf,
		isAudio,
		isVideo,
		isCode,
		isText,
		isUnsupportedImage,
		// TODO: this doesnt work yet..
		async reuploadWithParams(params) {
			this.previewUrl = 'https:' + buildQueryUrl(this.value.url, params)
			this.$refs.preview.open()
		},
		async previewConfirm() {
			this.$emit('input', {
				upload: this.previewUrl,
				fileName: this.value.fileName,
				contentType: this.value.contentType,
			})
			this.$refs.preview.close()
		},
		rotate(angle) {
			this.params.rot = (angle + 360) % 360
			return this.reuploadWithParams({ rot: this.params.rot, q: 100 })
		},
		async setWidth() {
			// TODO: validate that value is smaller
			let w = prompt('Enter the width in pixels')
			if (!w) return
			w = parseInt(w)
			if (w == null || w == -1) return
			if (w > this.value.details.image?.width) return alert('The width must be smaller than the current width')
			if (w > 5000) return alert('The maximum width is 5000 pixels')
			this.params.w = w
			return this.reuploadWithParams({ w, q: 100 })
		},
		async setHeight() {
			// TODO: validate that value is smaller
			let h = prompt('Enter the height in pixels')
			if (!h) return
			h = parseInt(h)
			if (h == null || h == -1) return
			if (h > this.value.details.image?.height) return alert('The height must be smaller than the current height')
			if (h > 5000) return alert('The maximum height is 5000 pixels')
			this.params.h = h
			return this.reuploadWithParams({ h, q: 100 })
		},
		crop(aspect) {
			this.cropShow = true
			this.cropAspect = aspect
		},
		async cropSave(dims) {
			this.cropShow = false
			const tl = dims.x
			const tt = dims.y
			const tr = this.value.details.image.width - dims.x - dims.w
			const tb = this.value.details.image.height - dims.y - dims.h
			const params = { tl, tt, tr, tb, q: 100 }
			return this.reuploadWithParams(params)
		},
	},
	mounted() {
		this.params = this.emptyParams
	},
}
</script>

<style scoped>
.view,
.dropzone { width: 100%; background-color: white; border: 1px dashed rgb(207, 217, 224); border-radius: 5px; }
.uploadcare--dragging .dropzone { background: silver; }
.view { border-style: solid; text-align: center; }
button { display: block; border: 1px solid rgb(207, 217, 224); box-shadow: rgb(25 37 50 / 8%) 0px 1px 0px; border-radius: 6px; cursor: pointer; font-weight: 500; outline: none; transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s; color: rgb(17, 27, 43); background-color: rgb(255, 255, 255); font-size: 0.875rem; line-height: 1.25; padding: 0.5rem 1rem; min-height: 40px; }

.message { text-align: center; margin: 80px 0 0; color: gray; }
.dropzone button { margin: 0 auto 20px; }

.view { margin-bottom: 15px; }
.view img { min-width: 50px; max-width: 500px; min-height: 50px; max-height: 300px; display: block; /*margin: 0 auto;*/ }

.buttons { display: flex; gap: 15px; align-items: center; }
.buttons[ disabled ] { opacity: 0.5; pointer-events: none; }
.button { background: white; border-radius: 5px; border: 1px solid var(--color-element-mid); padding: 10px 15px; text-decoration: none; color: var(--color-text-dark); white-space: nowrap; }
.button:hover { background: #eee; }
.button .mdi { font-size: 18px; }

.view .placeholder { font-size: 50px; margin: 50px 0; display: inline-block; color: var(--color-element-dark) }
</style>

<style>
.FileField .fsp-drop-pane__container { box-sizing: border-box; width: 100%; background-color: rgb(247, 249, 250); border: 1px dashed rgb(207, 217, 224); border-radius: 5px; padding: 50px 0; }
</style>