var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"JsonAutotestTimeline",staticStyle:{"flex":"1"}},[(_vm.slaData && _vm.executionData?.length)?_c('div',{staticClass:"intervals executions"},_vm._l((_vm.executionData),function(execution){return _c('div',{key:execution.id,staticClass:"interval execution",class:{
				night: _vm.isNight(execution.timestamp),
				success: execution.testResult == 1,
				failure: execution.testResult == 0,
			},style:({ left: _vm.getLeftPercent(execution.timestamp) + '%' }),attrs:{"title":execution.testResult}})}),0):_vm._e(),(_vm.slaData)?_c('div',{staticClass:"intervals data"},_vm._l((_vm.slaData.intervals),function(interval){return _c('div',{key:interval.intervalStart,staticClass:"interval bar",class:{
				up: interval.minutes == interval.downMinutes || interval.downMinutes == 0,
				down: interval.minutes > interval.downMinutes && interval.downMinutes > 0,
				partial: interval.downMinutes > 0 && interval.minutes > interval.downMinutes,
				night: _vm.isNight(interval.intervalStart),
				hourStart: interval.intervalStart.includes('00:00+'),
				dayStart: interval.intervalStart.includes('00:00:00+'),
			},attrs:{"title":interval.intervalStart + '\n' + interval.minutes + ' min, ' + interval.downMinutes + ' min down'}})}),0):_vm._e(),(_vm.slaData && _vm.showScale)?_c('div',{staticClass:"intervals scale"},_vm._l((_vm.slaData.intervals),function(interval,i){return _c('div',{key:interval.intervalStart,staticClass:"interval text",class:{
				night: interval.intervalStart.substring(11, 13) < 6 || interval.intervalStart.substring(11, 13) >= 20,
				hourStart: interval.intervalStart.includes('00:00+'),
				dayStart: interval.intervalStart.includes('00:00:00+'),
			}},[_c('label',[(interval.intervalStart.includes('00:00:00+') || i == 0)?[_c('b',[_vm._v(_vm._s(interval.intervalStart.substring(5, 10)))])]:(interval.intervalStart.includes('00:00+'))?[_vm._v(_vm._s(interval.intervalStart.substring(11, 16)))]:_vm._e()],2)])}),0):_vm._e(),(!_vm.slaData)?_c('div',{staticClass:"loading"},[_vm._v("loading..")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }