<template>
	<div class="content">
		<Head style="padding-left: 20px;">
			<AssetFilter v-model="filter" :def="def" style="position: relative; z-index: 1000;" />
			<div v-if="permissions.userCanCreateAssets()">
				<button class="add" @click="$refs.addMenu.open()">Add Asset <mdi chevron-down /></button>
				<Menu ref="addMenu" style="white-space: nowrap;" right="20px">
					<ul>
						<li @click="singleAssetCreate()">Single asset</li>
						<li @click="$refs.addMedia.open()">Multiple assets</li>
					</ul>
				</Menu>
				<Dialog ref="addMedia">
					<h1>Upload multiple assets</h1>
					<div style="padding: 20px;">
						<AssetMultiUpload @done="$refs.assetTable.filterChanged(true)" />
					</div>
				</Dialog>
			</div>
		</Head>
		<div class="body">
			<!--
			<Filters
				class="filters"
				:currentFilter="filter"
				style="position: fixed; top: 100px; bottom: 0; left: 0; overflow: auto;"
				@setFilter="setFilter"
				name="assetFilters"
			/>
			<div class="entryTableContainer">
			-->
				<AssetTable
					ref="assetTable"
					:filter="filter"
					:selectable="false"
					@input="selected"
					@skipChanged="skipChanged"
					:initialSkip="skip"
				/>
			<!--
			</div>
			-->
		</div>
	</div>
</template>

<script>
import AssetFilter from '../components/fields/AssetFilter.vue'
import AssetTable from '../components/fields/AssetTable.vue'
import Menu from '../components/Menu.vue'
import Dialog from '../components/Dialog.vue'
import Head from './Head.vue'
import ProviderMixin from './ProviderMixin.js'
import uploadcare from 'uploadcare-widget'
import AssetMultiUpload from '../components/AssetMultiUpload.vue'
import EntryApiMixin from '../components/EntryApiMixin'
import Filters from './Filters.vue'
import { columnFieldsForColumnIds, newFilterForStorage } from '../components/fields/FilterUtil'

// TODO: load list based on filter

export default {
	name: 'Media',
	mixins: [ ProviderMixin, EntryApiMixin ],
	components: { AssetFilter, AssetTable, Menu, Dialog, Head, AssetMultiUpload, Filters },
	inject: [ 'base', 'endpoint' ],
	data: () => ({
		filter: { search: '', filters: [], order: [ '-sys.updatedAt' ] },
		// TODO: ??
		def: {"id":"x","name":"x","type":"Array","localized":false,"required":false,"validations":[],"disabled":false,"omitted":false,"items":{"type":"Link","validations":[],"linkType":"Entry"}},
		skip: 0,
	}),
	watch: {
		filter: {
			deep: true,
			handler(filter) {
				const query = {
					search: filter.search,
					skip: this.skip,
					filters: filter.filters.map(f => f.storage),
				}
				this.$router.replace({ query }).catch(() => {})
				localStorage['filterQueryMedia'] = JSON.stringify({ ...query, spaceId: this.spaceId, environment: this.environment })
			},
		},
	},
	methods: {
		routerBeforeEach(to, from, next) {
			// TODO: if we only changed the filter -> return here
			next()
		},
		selected(entries) {
			this.$router.push(this.base + '/assets/' + entries[0].sys.id)
		},
		skipChanged(skip) {
			this.skip = skip
			if (this.$route.query.skip == skip) return
			this.$router.push({ query: { ...this.$route.query, skip } })
		},
		async singleAssetCreate() {
			const asset = await this.$httpPost(this.endpoint + '/assets', this.newAsset(), {})
			this.$router.push(this.base + '/assets/' + asset.sys.id)
		},
		uploadcareMultiAssetCreate() {
			this.$refs.addMedia.open()
			this.$nextTick(() => {
				// TODO: we should switch to a key authorization for this upload event - how?
				// TODO: switch to the new web component widget? (currently beta)
				const widget = uploadcare.Widget("#uploader", { publicKey: 'ac98bcc2f9c91ea49928' })
				const dialog = widget.openDialog()
				widget.onUploadComplete(async (info) => {
					console.log('UPLOADED', info)
					const asset = await this.$httpPost(this.endpoint + '/assets', this.newAsset({
						fields: {
							file: {
								// TODO: locale
								de: {
									// TODO: we should include the uuid once we switch to CH2
									//uuid: info.uuid,
									upload: info.cdnUrl,
									fileName: info.name,
									contentType: info.mimeType,
								}
							},
							title: {
								// TODO: locale
								// TODO: remove file extension
								de: info.name.replace(/\.\w+$/, ''),
							},
						},
					}), {
						headers: {
							'content-type': 'application/vnd.contentful.management.v1+json',
						},
					})
					// TODO: on error: show error in dialog
					console.log('CREATED', asset)
					// TODO: locale
					const processResponse = await this.$httpPut(this.endpoint + '/assets/' + asset.sys.id + '/files/de/process', undefined, {
						headers: {
							'content-type': 'application/vnd.contentful.management.v1+json',
						    'x-contentful-version': asset.sys.version,
						},
					})
					console.log('PROCESSED', processResponse)

					this.$refs.addMedia.close()
					
					const sleep = ms => new Promise(r => setTimeout(r, ms))
					await sleep(500)

					// TODO: we should not reload the whole table, we should smartly push the new objects into the table
					this.$refs.assetTable.loadAssets()
				})
				// TODO: remove button after dialog closes (or remove with styling)
			})
		},
		filestackMultiAssetCreate() {
			/* FILESTACK
			//const client = window['filestack'].init('AW97bQuu4Ru3nYEbxcyFZz') // CF
			const client = window['filestack'].init('A1q5c1OIQD6zUeY39yVwjz') // Mine
			client.picker({
				onFileUploadFinished: async (e) => {
					console.log('UPLOADED', e)
					const asset = await this.$httpPost(this.endpoint + '/assets', this.newAsset({
						fields: {
							file: {
								de: {
									upload: e.url,
									fileName: e.filename,
									contentType: e.mimetype,
								}
							},
							title: {
								// TODO: locale
								// TODO: remove file extension
								de: e.originalFile.name.replace(/\.\w+$/, ''),
							}
						}
					}), {
						headers: {
							'content-type': 'application/vnd.contentful.management.v1+json',
						},
					})
					console.log('CREATED', asset)
					// TODO: locale
					const processResponse = await this.$httpPut(this.endpoint + '/assets/' + asset.sys.id + '/files/de/process', undefined, {
						headers: {
							'content-type': 'application/vnd.contentful.management.v1+json',
						    'x-contentful-version': asset.sys.version,
						},
					})
					console.log('PROCESSED', processResponse)
					// TODO: we should not reload the whole table, we should smartly push the new objects into the table
					this.$refs.assetTable.loadAssets()
				},
			}).open()*/
		},
		setFilter(filter) {
			this.filter.filters = filter.filters
			this.filter.search = filter.search
			this.filter.order = filter.order
		},
	},
	// this needs to happen early, before the table is created so skip is transported there
	created() {
		// TODO-3: add name of space
		document.title = 'Media'

		let view = this.$route.query
		if (!view || Object.keys(view).length == 0) {
			view = JSON.parse(localStorage['filterQueryMedia'] ?? '{}')
			if (view.spaceId != this.spaceId || view.environment != this.environment) view = {}
		}
		if (view) {
			
			// fix query format encoding
			if (!view.filters) view.filters = []
			if (!Array.isArray(view.filters)) view.filters = [view.filters]
			if (view.filters.length > 0 && typeof view.filters[0] == 'string')
				view.filters = view.filters.map(f => f.split(',', 3))

			this.setFilter({
				contentType: view.contentType,
				filters: view.filters?.map?.(f => newFilterForStorage(f)) ?? [],
				search: view.search,
			})

			if (view.skip) this.skip = parseInt(view.skip)
		}
	},
}
</script>

<style scoped>
.EntryFilter { flex-grow: 1; }
h1 { flex-shrink: 0; font-size: 20px; font-weight: 600; margin: 0; padding: 0; width: 250px; padding: 5px 0; }
button.add { white-space: nowrap; background-color: var(--primary); height: 40px; padding: 8px 10px 8px 15px; color: white; border: 0; border-radius: 6px; font-size: 14px; font-weight: 500; cursor: pointer; }
.entryTableContainer { flex-grow: 1; margin: 20px; margin-left: 280px; }

@media (max-width: 800px) {
	.filters { display: none; }
	.entryTableContainer { margin-left: 20px; }
}
</style>