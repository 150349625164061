
export function getStatus(entry) {
	if (!entry || !entry.sys.updatedAt) return 'loading..'
	if (entry.sys.archivedAt) return 'archived'
	if (!entry.sys.publishedAt) return 'draft'
	if (entry.sys.updatedAt > entry.sys.publishedAt) return 'changed'
	return 'published'
}

export default {
	name: 'EntryStatus',
	inject: [ 'ScheduledActions' ],
	props: {
		locale: String,
		entry: Object,
		name: String,
		publishedName: { type: String, default: 'published' },
		// for some use cases we dont want to show the scheduled-icon
		hideScheduled: Boolean,
	},
	computed: {
		status() {
			if (this.name) return this.name
			// add recalc listeners
			this.entry.sys.version
			this.entry.sys.publishedAt
			const status = getStatus(this.entry)
			if (status == 'published')
				return this.publishedName
			return status
		},
		entryScheduledActions() {
			// trigger reactivity, because the byId lookup does not do that in vue2
			this.ScheduledActions.scheduledActions_all
			return this.ScheduledActions.scheduledActions_byId[this.entry.sys.id]
		},
		isScheduled() {
			if (!this.ScheduledActions) return false
			return !!this.entryScheduledActions?.length
		},
		schedule() {
			if (!this.ScheduledActions) return
			if (!this.entryScheduledActions?.length) return
			return this.entryScheduledActions
				.map((sa) => sa.scheduledFor.datetime + ': ' + sa.action)
				.join('\n')
		},
	},
}
