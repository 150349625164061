
// TODO: loading bar

export default {
	name: 'JsonAutotestTimeline',
	inject: [ 'defaultLocale', 'eventBus' ],
	props: {
		testcase: Object,
		target: Object,
		runner: String,
		value: Object, // { fromDate, toDate, interval }
		showScale: Boolean,
	},
	data: () => ({
		slaData: null,
		executionData: null,
		nightStart: 21,
		nightEnd: 6,
	}),
	computed: {
		targetName() {
			return this.target?.fields?.type?.[this.defaultLocale]
		},
		metisId() {
			return this.target?.fields?.metisId?.[this.defaultLocale]
		},
	},
	watch: {
		value: {
			handler() {
				this.load()
			},
			deep: true,
		},
	},
	methods: {
		async loadSlaData(installationId, target, runner, interval, fromDate, toDate) {
			return await this.$httpPost('https://metis.alturos.com/etl/api/Zabbix/CalculateSla', {
				fromDate,
				toDate,
				interval,
				installationId,
				target,
				runner,
				// TODO: change to testcaseId once Andreas has changed the API
				testId: this.testcase.sys.id,
			})
		},
		// TODO: how to visualize this data?
		//       should we show a dot on the timeline for each execution?
		//       how to project the data onto the timeline?
		//       we have a min date and a max date from the slaData response (?)
		//       actualEnd, actualStart, calculate in %
		async loadExecutionData(installationId, target, runner, fromDate, toDate) {
			const r = await this.$httpGet('https://autotest-zabbix-gw.tools.contenthub.dev/runner/execution', {
				fromDate,
				toDate,
				installationId,
				target,
				runner,
				testcaseId: this.testcase.sys.id,
			})
			return r.results
		},
		getLeftPercent(time) {
			const start = new Date(this.slaData.actualStart).getTime()
			const end = new Date(this.slaData.actualEnd).getTime()
			const percent = (new Date(time).getTime() - start) / (end - start)
			return Math.round(percent * 1000) / 10
		},
		isNight(time) {
			// fix timestamp to be in local time
			time = new Date(time).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
			const hour = new Date(time).getHours()
			return hour < this.nightEnd || hour >= this.nightStart
		},
		async load() {
			this.slaData = await this.loadSlaData(this.metisId, this.targetName, this.runner, this.value.interval, this.value.fromDate, this.value.toDate)
			this.executionData = await this.loadExecutionData(this.metisId, this.targetName, this.runner, this.value.fromDate, this.value.toDate)
		},
	},
	async mounted() {
		await this.load()
	},
}
