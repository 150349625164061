<template>
	<div class="ReferencesField" :class="{ error }">
		<div v-for="(entryLink, e) of entries" :key="'e' + (entryLink?.sys?.id ?? e)"
			:draggable="def.type == 'Array'"
			@dragstart="dragstart($event, entryLink, e)"
			@dragover="dragover($event, entryLink, e)"
			@dragend="dragend($event, entryLink, e)"
			ondragstart="event.cancelBubble = true; event.target.style.opacity = '0.6';"
			ondragover="event.preventDefault(); event.cancelBubble = true;"
			ondragend="this.style.opacity = '1';"
			ondrop="event.cancelBubble = true;"
		>
			<!-- TODO: instead of triggering reload here
						we should probably do this at the save!
						even if we leave it at close, we should rather trigger the eventBus on the close button and not in this callback to be cleaner.
			-->
			<Entry :entry="entryLink"
				@click="$emit('subedit', entryLink.sys, () => { eventBus.$emit('reloadEntry_' + entryLink.sys.id) })"
				@errors="handleEntryValidationErrors(entryLink.sys.id, $event)"
				@remove="remove(e)"
				:allowedContentTypes="validations.linkContentType"
			>
				<template #actions>
					<div style="position: relative" @click="$event.cancelBubble = true">
						<!-- $refs.menu[e] TODO - inaccessibles are not added, so indexes are offset! -->
						<mdi dots-horizontal v-if="!disabled" @click="$refs['menu' + e][0].open()" style="margin-left: 5px;" />
						<Menu :ref="'menu' + e">
							<ul>
								<li @click="handleEntryValidationErrors(entryLink.sys.id, []); remove(e)">Remove</li>
								<li v-if="e > 0 || e < entries.length - 1" class="separator"></li>
								<!-- TODO: implement these -->
								<li v-if="e > 0">Move to top</li>
								<li v-if="e < entries.length - 1">Move to bottom</li>
							</ul>
						</Menu>
					</div>
				</template>
			</Entry>
		</div>
		<div class="add" v-if="value == null || def.type == 'Array' && value && (!validations.max || value.length < validations.max)">
			<button @click="$refs.addMenu.open()" class="openMenu" :disabled="disabled">
			<!-- menuOpen = !menuOpen -->
				<mdi plus />
				Add content
				<mdi chevron-down />
			</button>
			<Menu ref="addMenu" class="addMenu">
				<TypePickerList :showPickEntry="true"
					:allowedContentTypes="validations.linkContentType"
					@pickEntry="addExisting"
					@select="createEntry($event)"
					@close="menuOpen = false"
				/>
			</Menu>
			<div v-if="adding" ref="modal" class="modal">
				<div class="panel">
					<EntryPicker :def="def" :filter="filter"
						:allowedContentTypes="validations.linkContentType"
						@input="onPickerInput"
						@cancel="closeAdding"
						@ok="onPickerOk"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'
import Entry from './Entry.vue'
import EntryPicker from './EntryPicker.vue'
import TypePickerList from '../TypePickerList.vue'
import Menu from '../Menu.vue'
import EntryApiMixin from '../EntryApiMixin.js'

// TODO: out-binding for object mode

export default {
	name: 'ReferencesField',
	mixins: [ field, EntryApiMixin ],
	components: { Entry, EntryPicker, TypePickerList, Menu },
	inject: [ 'endpoint', 'eventBus' ],
	props: {
		value: [ Array, Object ],
	},
	data: () => ({
		model: null,
		menuOpen: false,
		adding: false,
		filter: { contentType: null, search: '', filters: [] },
		dragged: null,
		draggedIndex: null,
		entryValidationErrors: {},
		entryMenuOpen: null,
	}),
	computed: {
		entries() {
			if (!this.value) return []
			if (this.isArray) return this.value
			return [ this.value ]
		},
		isArray() {
			return Array.isArray(this.model)
		},
	},
	watch: {
		value(n, o) {
			this.validate()
		},
	},
	methods: {
		validate() {
			const eve = Object.values(this.entryValidationErrors).flat()
			if (this.isArray)
				this.onErrors([
					...eve,
					// TODO: what is the required semantics of CF here? does this mean the min is minimally 1?
					this.validateRequired(),
					this.validateArrayMin(),
					this.validateArrayMax(),
				])
			else
				this.onErrors([
					...eve,
					this.validateRequired(),
				])
		},
		addExisting() {
			this.menuOpen = false
			this.adding = true
			this.$modal()
		},
		remove(index) {
			if (this.isArray)
				this.model.splice(index, 1)
			else
				this.model = null
		},
		onPickerInput(selection) {
			if (this.def.type == 'Array') return
			// TODO: this doesnt work - probably because we cant expect array in model
			// for single-selections we immediately close the picker
			this.onPickerOk(selection)
		},
		onPickerOk(selection) {
			console.log('ReferencesField: onPickerOk', selection)
			// TODO: this is not really our own concern - move this to the emit in the picker?
			const links = []
			for (const sel of selection) {
				if (!sel) continue
				sel.selected = undefined
				sel.match = undefined
				const link = { sys: { id: sel.sys.id, linkType: 'Entry', type: 'Link' } }
				links.push(link)
			}
			if (this.def.type == 'Array') {
				if (!this.model) this.model = []
				this.model.push(...links)
			}
			else {
				this.model = links[0]
			}
			this.closeAdding()
		},
		closeAdding() {
			// for some reason we have to unmodal the dialog in this case, otherwise it stays as a zombie
			this.$unmodal()
			this.adding = false
		},
		dragstart(event, item, i) {
			this.dragged = item;
			this.draggedIndex = i;
		},
		dragover(event, item, i) {
			if (!this.dragged) return
			if (i == this.draggedIndex) return
			// remove dragged item at its position
			this.model.splice(this.draggedIndex, 1)
			// insert the dragged item 
			this.model.splice(i, 0, this.dragged)
			this.draggedIndex = i
			console.log('M', this.model.map(m => m.sys.id))
		},
		dragend(event, item, i) {
			this.dragged = null
		},
		async createEntry(contentType) {
			this.menuOpen = false
			if (typeof contentType == 'string')
				contentType = window['typeLookup'][contentType]
			const entry = await this.$httpPost(this.endpoint + '/entries', this.newEntry(), {
				headers: {
					'X-Contentful-Content-Type': contentType.sys.id,
				},
			})
			const link = { sys: { id: entry.sys.id, linkType: 'Entry', type: 'Link' } }
			if (this.def.type == 'Array') {
				if (!this.model) this.model = []
				this.model.push(link)
			}
			else {
				this.model = link
			}
			this.$emit('subedit', entry.sys, () => { this.eventBus.$emit('reloadEntry_' + link.sys.id) })
		},
		handleEntryValidationErrors(entryId, errors) {
			console.log('RF got validation errors', entryId, errors)
			this.entryValidationErrors[entryId] = errors
			this.validate()
			//this.onErrors(Object.values(this.entryValidationErrors).flat())
		},
	},
	mounted() {
		if (this.validations.linkContentType) {
			// TODO: adding the filter may not be the right thing to do.
			//       we also set the allowedContentTypes on the widget, i think we should rather transport that into the EntryApi..
			//       that currently restricts the type dropdown, while this filter sets a restriction on the EntryApi requests.
			const filter = {
				id: Math.random(),
				field: 'contentType.sys.id',
				scope: 'sys',
				type: 'Hidden',
				mode: 'in',
				value: this.validations.linkContentType,
				query: { k: '', v: '' },
			}
			this.filter.filters = [ filter ]
		}
	},
}
</script>

<style scoped>
.ReferencesField { display: flex; flex-direction: column; gap: 10px; }
.ReferencesField > .add { border: 1px dashed rgb(103, 114, 138); text-align: center; border-radius: 6px; padding: 2rem; }
svg.icon { fill: currentcolor; width: 18px; height: 18px; margin: 0 7px; }
.openMenu { display: flex; white-space: nowrap; margin: 0 auto; border: 1px solid rgb(207, 217, 224); box-shadow: rgb(25 37 50 / 8%) 0px 1px 0px; border-radius: 6px; cursor: pointer; font-family: var(--font-stack-primary); overflow: hidden; transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s; color: rgb(17, 27, 43); background-color: rgb(255, 255, 255); font-size: 0.875rem; line-height: 130%; padding: 7px 7px; min-height: 32px; font-weight: bold; }
.icon-ellipsis { font-size: 14px; }
.addMenu { width: 290px; margin: 0 auto; overflow-x: hidden; }
</style>
